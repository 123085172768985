@import url("https:\/\/fonts.googleapis.com\/css?family=Roboto:400,500,700&subset=cyrillic");

.toolbar {
	display: inline;
	height: 100%;
}

.toolbar .btn {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 70%;
	font-weight: 700;
	position: relative;
	padding: 5px 12px;
	background-color: #31d0aa;
	text-decoration: none;
	font-size: 14px;
	color: white;
	border-radius: 5px;
	border: 1px solid #31d0aa;
	z-index: 1;
	outline: none;
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
	pointer-events: none;
	cursor: default;
	&:hover {
		color: white;
	}
}

span.live-icon {
	display: inline-block;
	top: calc(50% - 5px);
	background-color: white;
	width: 8px;
	height: 8px;
	margin-right: 5px;
	border: 1px solid rgba(black, 0.1);
	border-radius: 50%;
	z-index: 1;
	&:before {
		margin: -1px 0 0 -1px;
		content: "";
		display: block;
		position: absolute;
		background-color: rgba(white, 0.6);
		width: 8px;
		height: 8px;
		border-radius: 50%;
		animation: live 2s ease-in-out infinite;
		z-index: -1;
	}
}

@keyframes live {
	0% {
		transform: scale(1, 1);
	}
	100% {
		transform: scale(3.5, 3.5);
		background-color: rgba(white, 0);
	}
}

.toolbar .not_btn {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 70%;
	font-weight: 700;
	position: relative;
	padding: 5px 12px;
	background-color: rgba(rgb(102, 102, 102), 0.9);
	text-decoration: none;
	color: #333;
	border-radius: 5px;
	border: 1px solid rgb(102, 102, 102);
	z-index: 1;
	outline: none;
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
	&:hover {
		background-color: rgba(rgb(102, 102, 102), 1);
		border: none;
		box-shadow: 0px 5px 10px darken(rgb(60, 60, 60), 7%);
	}
}

span.not_live-icon {
	display: inline-block;
	top: calc(50% - 5px);
	background-color: white;
	width: 8px;
	height: 8px;
	margin-right: 5px;
	border: 1px solid rgba(black, 0.1);
	border-radius: 50%;
	z-index: 1;
}
