.countryCode_containerPeople {
	border: 0px solid transparent;
	height: 40px;
}

.countryCode_inputClassPeople {
	border: 1px solid transparent !important;
	width: 100% !important;
	height: 40px !important;
	font-family: "Poppins";
	font-size: 12px !important;
	font-weight: 300 !important;
	color: #4a4a68 !important;
	background-color: #f2f2f2 !important;
}

.countryCode_buttonClassPeople {
	border-right: 1px solid white !important;
	border-left: 1px solid transparent !important;
	border-top: 1px solid transparent !important;
	border-bottom: 1px solid transparent !important;
	background-color: #f2f2f2 !important;
}

.countryCode_dropdownClassPeople {
	width: 220px !important;
	max-height: 150px !important;
	font-family: "Poppins";
	font-size: 11px !important;
	font-weight: 500 !important;
	color: #4a4a68 !important;
}

#helmet_new_person_input {
	height: 45px;
}

.disabled-row {
	background-color: rgb(251, 230, 222); /* Example background color */
}
.disabled-row:hover {
	background-color: rgb(251, 230, 222) !important; /* Example background color */
}

.invalid-row {
	background-color: rgb(242, 242, 242); /* Example background color */
}
.invalid-row:hover {
	background-color: rgb(242, 242, 242) !important; /* Example background color */
}

.geocoder-input > div {
	background: transparent;
	border: none;
	max-width: none;
	width: 100%;
}

.geocoder-input > div > svg {
	margin: 0 !important;
}

.geocoder-input {
	width: 100%;
}
