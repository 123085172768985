.main-container {
	overflow-x: hidden;
}

.scrollable-container {
	display: flex;
	overflow-x: auto;
	flex-shrink: 0;
	justify-content: space-between;
}

.chart-container {
	flex: 0 0 auto;
}

.location_title {
	color: #323232;
	text-align: center;
	font-size: 16px;
	font-family: Poppins;
	font-weight: 500;
}

.custom_legend_arrow_div_top {
	border-radius: 4px 4px 0 0;
}

.custom_legend_arrow_div_top:hover {
	background-color: #e3e3e3;
	cursor: pointer;
}

.custom_legend_arrow_div_bottom {
	border-radius: 0 0 4px 4px;
}

.custom_legend_arrow_div_bottom:hover {
	background-color: #e3e3e3;
	cursor: pointer;
}

/* DatePicker reporting */

.date_picker_range_reporting {
	box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
		0px 9px 46px 8px rgba(0, 0, 0, 0.12);
	font-family: "Poppins";
	font-weight: 400;
	font-size: 1rem;
	border-radius: 4px;
	border: none;
	/* width: 400px;  */
}
.react-datepicker__day--selected {
	background-color: #2673f0;
}
.react-datepicker__triangle {
	display: none;
}

.react-datepicker__current-month {
	/* background-color: #3077D3; */
	margin-bottom: 1.5rem;
	font-weight: 500;
}

.react-datepicker__header {
	border-bottom: none;
	background-color: #ffffff;
}

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
	padding-top: 2rem !important;
}

/* .react-datepicker__navigation-icon {
	padding-top: 0.5rem;
}

.react-datepicker__navigation--previous {
	padding-top: 4rem !important;
}

.react-datepicker__navigation--next {
	padding-top: 4rem !important;
} */

.date_picker_range_reporting .react-datepicker__day-name {
	color: #b5b5b5 !important;
}

#custom-portal .react-datepicker__portal {
	background-color: transparent !important; /* Use transparent background */
	/* or */
	/* background-color: #f5f5f5; */
}

.export_daily_workers_dropdown_btn {
	background: #3077d3 !important;
	border-radius: 2px !important;
	font-weight: 700 !important;
	font-size: 11.3px !important;
	font-family: "Poppins" !important;
	height: 2.7rem !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	letter-spacing: 0.4px !important;
}

.export_daily_workers_dropdown_btn:hover {
	border-color: transparent !important;
	background-color: #23579c !important;
	box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
		0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}

/* Change color of the arrow in the dropdown */
.export_daily_workers_dropdown_btn:after {
	color: #f4f4f4 !important;
}
