.to-hide-text {
	width: 170px;
	display: inline-block;
	white-space: nowrap;
	overflow: hidden !important;
	text-overflow: ellipsis;
	/* border: 1px solid white; */
	/* height: auto; */
}

.to-hide-text:hover {
	width: 170px;
	white-space: unset;
	text-overflow: unset;
	/* margin-top:0.5rem; */
	transition: 0.2s ease-in-out;
}

.brand:hover {
	/* border: 1px solid red; */
	/* margin-top:0.5rem; */
	height: auto;
}

/* Brand.css */

.svg-icon {
	padding: 0.5rem;
}

.brand-container {
	display: flex;
	padding: 0 16px;
	margin-top: 1rem;
	width: 100%;
}

.brand-content {
	display: flex;
	align-items: center;
	width: 100%;
}

.brand-logo {
	width: 16%;
	min-width: 16px; /* Ensures a minimum size for the image */
	margin-right: 1rem;
}

.brand-title {
	flex-grow: 1; /* Title will take up the remaining space */
	font-size: 16px;
	font-weight: 700;
	line-height: 1.6;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.brand-icon {
	margin-left: auto;
	background: none;
	border: none;
	cursor: pointer;
}

.brand-icon-closed {
	margin-left: 0.5rem;
	background: none;
	border: none;
	cursor: pointer;
}

/* Responsive adjustments */
@media (max-width: 768px) {
	.brand-content {
		flex-direction: column;
		align-items: center;
	}

	.brand-title {
		margin-top: 0.5rem;
		text-align: center;
	}

	.brand-logo {
		width: 20%;
		margin: 0 auto;
	}
}

@media (min-width: 768px) {
	.brand-content {
		flex-direction: row;
	}
}
