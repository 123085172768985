#zonesId {
	white-space: nowrap;
}

#POIsId {
	white-space: nowrap;
}

#resourcesId {
	white-space: nowrap;
}

#orientationId {
	white-space: nowrap;
}

#routesId {
	white-space: nowrap;
	/* margin-top: 2px; */
}

.button_dropdown_header_design_studio::after {
	color: #f4f4f4 !important;
}

/* Change color of the arrow in the dropdown */
.dropdown-toggle.button_dropdown_header_design_studio:after {
	color: #f4f4f4 !important;
}

.manage-infrastructure-button-label {
	text-align: center;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: 0.56px;
	text-transform: uppercase;
}

.header-title-svg-div {
	width: 30px;
	height: 30px;
	margin-right: 12px;
	margin-bottom: 10px;
}
.header-title-svg {
	height: 2.5rem;
	width: 2.5rem;
	fill: #4a4a68;
}

@media only screen and (max-width: 1150px) {
	#zonesId {
		white-space: normal;
	}

	#POIsId {
		white-space: normal;
	}

	#resourcesId {
		white-space: normal;
	}
	#orientationId {
		white-space: normal;
	}
	#routesId {
		white-space: normal;
	}
}

/* Tablet  */
@media only screen and (max-width: 780px) {
}

/* Mobile  */
@media only screen and (max-width: 650px) {
	.header_container {
		display: none;
	}
}
