.dashboard_top_row_info_main_div {
	height: 180px;
}

.announcement-card-body {
	height: 100%;
	width: 100%;
	font-size: 14px;
	line-height: 20px;
}

/* .announcement-card-header {
	padding: 5px 0px;
	height: 100%;
	width: 100%;
} */

.list-item-title {
	font-size: 10px;
}

@media only screen and (max-width: 768px) {
	.dashboard_top_row_info_main_div {
		height: auto;
	}

	#dashboard_GlobalPeopleInfo_div {
		/* margin-bottom: 1rem; */
	}

	.top_row_info_text {
		white-space: nowrap;
	}

	.announcement-card-body {
		height: 138px;
		width: 100%;
		font-size: 12px;
		line-height: 18px;
		font-weight: 500;
	}

	.list-item-title {
		font-size: 16px;
	}
}
